import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Button } from "src/components/ui/button";
import { FaEthereum, FaArrowLeft } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "src/lib/axiosInstance";
import { signMessage } from "src/lib/signature.utils";
import Popup from "src/components/Popup";
import { getCookieData } from "src/lib/cookies";
import { useAddress, useSigner } from "@thirdweb-dev/react";
import LoginPopup from "src/components/LoginPopup";

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

const slideIn = {
  hidden: { x: -50, opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { duration: 0.5 } },
};

export default function WalletGuidePage() {
  const [popupMessage, setPopupMessage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const user = useRef<any>({});
  const signer = useSigner();
  const address = useAddress();

  const closePopup = () => {
    setShowPopup(false);
    setPopupMessage(null);
  };

  const openLoginPopup = () => {
    setShowLoginPopup(true);
  };

  const closeLoginPopup = () => {
    setShowLoginPopup(false);
  };

  useEffect(() => {
    if (signer) {
      user.current.signer = signer;
    }
  }, [signer]);

  useEffect(() => {
    if (address) {
      user.current.address = address;
    }
  }, [address]);

  const handleSignMessage = async () => {
    const currentAddress = user.current.address;
    const currentSigner = user.current.signer;

    if (!currentSigner) {
      setPopupMessage("Connect your wallet first.");
      setShowPopup(true);
      return;
    }

    try {
      const message = await signMessage(currentAddress);
      const sign = await currentSigner.signMessage(message);

      const data = { address: currentAddress, signature: sign };

      const resp = await axiosInstance.post("/wallet", data, {
        headers: { Authorization: `Bearer ${await getCookieData()}` },
      });

      setPopupMessage(resp.data.message);
      if (resp.data.status) {
        window.location.replace("/");
      }
      setShowPopup(true);
    } catch (error) {
      setPopupMessage((error as any).message);
      setShowPopup(true);
    }
  };

  return (
    <div
      className="min-h-screen bg-gray-900 flex items-center justify-center p-4 sm:p-6"
      style={{ paddingTop: "90px" }}
    >
      <motion.div
        className="bg-gray-800 p-6 sm:p-8 rounded-lg shadow-lg w-full max-w-2xl text-white"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <Link
          to="/"
          className="flex items-center text-purple-400 hover:text-purple-300 mb-6"
        >
          <FaArrowLeft className="mr-2" /> Back to Home
        </Link>
        <motion.h1
          className="text-2xl sm:text-3xl font-bold text-center mb-6"
          variants={slideIn}
        >
          Connect Your Wallet
        </motion.h1>
        <motion.p className="text-lg mb-6" variants={fadeIn}>
          To use MetaWorm, you need to connect a Web3 wallet. If you don&apos;t
          have one yet, don&apos;t worry! Follow the steps below to get started.
        </motion.p>
        <motion.div className="space-y-6 sm:space-y-8" variants={fadeIn}>
          <motion.div variants={slideIn}>
            <h2 className="text-xl font-semibold mb-2">1. Choose a Wallet</h2>
            <p>
              We recommend MetaMask for beginners. It&apos;s easy to use and
              widely supported.
            </p>
          </motion.div>
          <motion.div variants={slideIn}>
            <h2 className="text-xl font-semibold mb-2">
              2. Install the Wallet
            </h2>
            <p>
              Visit the official website of your chosen wallet and follow their
              installation instructions.
            </p>
            <Button
              onClick={() =>
                window.open("https://metamask.io/download/", "_blank")
              }
              className="mt-2 bg-orange-500 hover:bg-orange-600"
            >
              <FaEthereum className="mr-2" /> Get MetaMask
            </Button>
          </motion.div>
          <motion.div variants={slideIn}>
            <h2 className="text-xl font-semibold mb-2">
              3. Create or Import a Wallet
            </h2>
            <p>
              Once installed, create a new wallet or import an existing one if
              you have one.
            </p>
          </motion.div>
          <motion.div variants={slideIn}>
            <h2 className="text-xl font-semibold mb-2">
              4. Connect to MetaWorm
            </h2>
            <p className="mb-4">
              After setting up your wallet, return to MetaWorm and click the
              &apos;Connect Wallet&apos; button, and proceed with registration.
            </p>
            <div style={{ textAlign: "center" }}>
              <Button
                onClick={() => openLoginPopup()}
                style={{
                  textAlign: "center",
                  marginTop: "1.5rem",
                  color: "black",
                  padding: "20px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                }}
              >
                Register
              </Button>
            </div>
          </motion.div>
        </motion.div>
        <motion.div className="mt-8 text-center" variants={fadeIn}>
          <Link to="/">
            <Button className="bg-purple-600 hover:bg-purple-700">
              Return to Home
            </Button>
          </Link>
        </motion.div>
      </motion.div>
      {showPopup && (
        <Popup
          title="Wallet Connect Status"
          message={popupMessage || ""}
          onClose={closePopup}
        />
      )}

      {showLoginPopup && <LoginPopup onClose={closeLoginPopup} />}
    </div>
  );
}
