import React from "react";
import { PlayCircleIcon, X } from "lucide-react";
import { Button } from "src/components/ui/button";

interface GlobalAccessibilityPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const GlobalAccessibilityPopup: React.FC<GlobalAccessibilityPopupProps> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-white"
          aria-label="Close popup"
        >
          <X size={24} />
        </button>
        <h2 className="text-2xl font-bold mb-4 text-white">
          Build Your Metaverse Venue
        </h2>
        <div className="space-y-6">
          <div>
            <div className="flex flex-row">
              <h2 className="text-xl font-semibold mb-2 text-purple-400">
                Tier 1: Standard Buildout
              </h2>
              <PlayCircleIcon
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/watch?v=n8ec7wD2G_M",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                style={{ cursor: "pointer", marginRight: "1rem" }}
                className="m-auto h-6 w-6 text-purple-400"
              />
            </div>
            <p className="text-gray-300">
              Get started with pre-designed templates, basic branding, and quick
              deployment—perfect for small businesses and events.
            </p>
          </div>
          <div>
            <div className="flex flex-row">
              <h2 className="text-xl font-semibold mb-2 text-purple-400">
                Tier 2: Premium Buildout
              </h2>

              <PlayCircleIcon
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/watch?v=rh0SP6ifwXY",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                style={{ cursor: "pointer", marginRight: "1rem" }}
                className="m-auto h-6 w-6 text-purple-400"
              />
            </div>
            <p className="text-gray-300">
              Create a fully customized, immersive metaverse experience with
              advanced features, tailored design, in-depth analytics, and a
              mobile app featuring a “Direct-to-Location” tool to seamlessly
              connect users to physical or metaverse venues.
            </p>
          </div>
          <p className="text-white font-medium">
            Choose the plan that fits your vision and bring your business into
            the metaverse!
          </p>
          <Button
            onClick={onClose}
            className="w-full bg-purple-600 hover:bg-purple-700 text-white"
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GlobalAccessibilityPopup;
