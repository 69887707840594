import React, { useState, useRef, useEffect } from "react";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { X } from "lucide-react";
import { FaGoogle, FaFacebook, FaEthereum } from "react-icons/fa";
import { signMessage } from "src/lib/signature.utils";
import { axiosInstance } from "src/lib/axiosInstance";
import Popup from "./Popup";
import { setCookieData } from "src/lib/cookies";
import { useAddress, useSigner } from "@thirdweb-dev/react";
import ConnectWalletButton from "src/connect-wallet/ConnectWalletButton";
import { Loader } from "./ui/loader";
interface LoginPopupProps {
  onClose: () => void;
}

const LoginPopup: React.FC<LoginPopupProps> = ({
  onClose,
}) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [popupMessage, setPopupMessage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState(false);

  const user = useRef<any>({});

  const signer = useSigner();

  const address = useAddress();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    if (signer) {
      user.current.signer = signer;
    }
  }, [signer]);

  useEffect(() => {
    if (address) {
      user.current.address = address;
    }
  }, [address]);

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const currentAddress = user.current.address;
      const currentSigner = user.current.signer;

      if (!email || !name) {
        setIsLoading(false);
        alert("please add all fields");
        return;
      }

      let sign: string;

      if (currentAddress && signer) {
        const message = await signMessage(currentAddress);

        sign = await currentSigner?.signMessage(message);

        if (!sign) {
          setPopupMessage("Please sign the message in wallet");
          setShowPopup(true);
          setIsLoading(false);
          return;
        }

        const data = {
          email: email,
          name: name,
          sign: sign,
          walletAddress: currentAddress,
        };

        const response = await axiosInstance.post(`/user-register`, data);

        if (response.data.status) {
          await setCookieData(response.data.token);
          window.location.reload();
        } else {
          setPopupMessage(response.data.message);
          setShowPopup(true);
          setIsLoading(false);
        }
      } else {
        setPopupMessage("Please connect your wallet and try again");
        setShowPopup(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setPopupMessage(
        (error as any).response?.data?.message || (error as any)?.message
      );
      setShowPopup(true);
      setIsLoading(false);
    }
  };

  const googleSSO = async () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL_SERVER}/auth/google`;
  };

  const faceBookSSO = async () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL_SERVER}/auth/facebook`;
  };

  const handleWeb3Login = async () => {
    const currentAddress = user.current.address;
    const currentSigner = user.current.signer;

    if (currentSigner && currentAddress) {
      try {
        const message = await signMessage(currentAddress);

        const sign = await currentSigner?.signMessage(message);

        const data = { address: currentAddress, signature: sign };

        const response = await axiosInstance.post("/auth/wallet", data);

        if (response.data.status) {
          setCookieData(response.data?.token);
          window.location.reload();
        } else {
          setPopupMessage(response.data?.message);
          setShowPopup(true);
        }
      } catch (error) {
        if((error as any).message == "User rejected."){
          setPopupMessage("Please Authenticate your wallet")
          setShowPopup(true)
          return
        }
        setPopupMessage((error as any).message);
        setShowPopup(true);
      }
    } else {
      setPopupMessage("Please connect your registered wallet");
      setShowPopup(true);
    }
  };
  const closePopup = () => {
    setShowPopup(false);
    setPopupMessage(null);
  };

  return (
    <div
      className="fixed inset-0 bg-black/80 flex items-center justify-center z-[100] p-4"
      style={{ paddingTop: "80px" }}
    >
      <div className="relative w-full max-w-4xl bg-[#0f1116] rounded-lg shadow-xl border border-gray-700 max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-gray-800 p-4 rounded-t-lg flex justify-between items-center z-10">
          <h2 className="text-xl font-bold text-white">MetaWorm Access</h2>
          <button
            className="text-gray-300 hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-gray-400 rounded-full p-1"
            aria-label="Close"
            onClick={onClose}
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="p-6 flex flex-col md:flex-row gap-6">
          <div className="flex-1">
            <h3 className="text-2xl font-extrabold text-white mb-4">
              Create Your Account
            </h3>
            <p className="text-gray-400 mb-6">
              Start your journey with us by registering using your details or
              Web3 wallet.
            </p>
            <form onSubmit={handleRegister} className="space-y-4">
              <Input
                type="text"
                placeholder="Enter your full name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full bg-gray-800/50 border border-gray-600 text-white placeholder-gray-500 h-12 rounded-md px-4"
              />
              <Input
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full bg-gray-800/50 border border-gray-600 text-white placeholder-gray-500 h-12 rounded-md px-4"
              />
              <div className="text-center">
                <ConnectWalletButton
                  login={false}
                  signup={false}
                  registerWallet={false}
                  claim={false}
                  handleWeb3Login={null}
                  handleRegister={null}
                  handleClaim={null}
                />
              </div>
              <Button
                type="submit"
                className="w-full bg-purple-600 hover:bg-purple-700 text-white h-12 text-base font-semibold rounded-md"
                disabled={!address}
              >
                {isLoading ? <Loader /> : "Register Now"}
              </Button>
            </form>

            <div className="relative my-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-700"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-4 text-gray-400 bg-gray-900">or</span>
              </div>
            </div>

            <div className="space-y-3">
              <Button
                onClick={() => googleSSO()}
                className="w-full bg-red-600 hover:bg-red-700 text-white h-12 text-base font-semibold rounded-md flex items-center justify-center"
              >
                <FaGoogle className="mr-2 h-5 w-5" /> Sign up with Google
              </Button>
              <Button
                onClick={() => faceBookSSO()}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white h-12 text-base font-semibold rounded-md flex items-center justify-center"
              >
                <FaFacebook className="mr-2 h-5 w-5" /> Sign up with Facebook
              </Button>
            </div>
          </div>

          <div className="hidden md:block w-px bg-gray-700"></div>

          <div className="flex-1 flex flex-col items-center justify-center bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-extrabold text-white mb-4">
              Already a member?
            </h3>
            <p className="text-gray-300 mb-6 text-center">
              {address
                ? "You’re almost there! Use your Web3 wallet for Authentication."
                : "Connect your Web3 wallet to start mining."}
            </p>
            {!address ? (
              <ConnectWalletButton
                claim={false}
                login={true}
                signup={true}
                registerWallet={false}
                handleWeb3Login={handleWeb3Login}
                handleRegister={null}
                handleClaim={null}
              />
            ) : (
              <Button
                onClick={handleWeb3Login}
                className="w-full bg-gradient-to-r from-orange-400 to-red-500 hover:from-orange-500 hover:to-red-600 text-white h-12 text-base font-semibold rounded-md flex items-center justify-center shadow-md hover:shadow-lg"
              >
                <FaEthereum className="mr-2 h-5 w-5" /> Authenticate Wallet
              </Button>
            )}
          </div>
        </div>
      </div>
      {showPopup && (
        <Popup
          title="Login Status"
          message={popupMessage || ""}
          onClose={closePopup}
        />
      )}
    </div>
  );
};

export default LoginPopup;
