import { Routes, Route } from "react-router-dom";
// import './App.css';
import HomePage from "./pages/home/HomePage";
import Layout from "./layout/Layout";
import ClaimNFTPage from "./pages/claim-nft/ClaimNFT";
import AboutUsPage from "./pages/about-us/AboutUs";
import FacebookCallback from "./pages/auth/facebook/callback/page";
import GoogleCallback from "./pages/auth/google/callback/page";
import DashboardPage from "./pages/dashboard/Dashboard";
import ResetPassword from "./pages/reset-password/ResetPassword";
import TriviaPage from "./pages/trivia/Trivia";
import VerifyEmail from "./pages/verify-email/VerifyEmail";
import WalletGuidePage from "./pages/wallet-guide/WalletGuide";
import LibraryPage from "./pages/library/LibraryClient";
import ProtectedRoute from "./protected/ProtectedRoutes";
import NotFound from "./pages/not-found/NotFound";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsConditions from "./pages/terms-and-conditions/TermsAndConditions";
import UserOnboarding from "./user-onboarding/UserOnboarding";
import ImportNFT from "./pages/import-nft/ImportNFT";

function App() {

  return (
      <Layout>
        <Routes>
          <Route element={<UserOnboarding />}>
          <Route path="/import-nft" element= {<ImportNFT />} />
              {/* <Route path="/reset-password" element ={ <ResetPassword />} /> */}
              <Route path="/verify-email" element ={ <VerifyEmail />} />
              <Route path="/wallet-guide" element ={ <WalletGuidePage />} />
              <Route path="/library" element ={ <LibraryPage />} />
              <Route path="/" element= {<HomePage />} />
              <Route path="/about-us" element= {<AboutUsPage />} />
              <Route path="/privacy-policy" element ={ <PrivacyPolicy />} />
              <Route path="/terms" element ={ <TermsConditions />} />
              <Route element={ <ProtectedRoute/>} >
                <Route path="/claim-nft" element= {<ClaimNFTPage />} />
                <Route path="/dashboard" element= {<DashboardPage />} />
                <Route path="/trivia" element ={ <TriviaPage />} />
              </Route>
              <Route path="/auth/facebook/callback" element= {<FacebookCallback />} />
              <Route path="/auth/google/callback" element= {<GoogleCallback />} />
              <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
      </Layout>
  );
}

export default App;
