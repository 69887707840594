
import { axiosInstance } from 'src/lib/axiosInstance'
import { setCookieData } from 'src/lib/cookies';
import { useEffect, useState } from 'react'
import Popup from 'src/components/Popup';

const FacebookCallback = () => {
  const [authCode, setAuthCode] = useState<string | null>(null)
  const [popupMessage, setPopupMessage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search)
      const code = params.get('code')

      if (code) {
        setAuthCode(code)
        authenticateWithFacebook(code)
      }
    }
  }, [])

  const authenticateWithFacebook = async (code: string) => {
    try {
      const response = await axiosInstance.get(`/auth/facebook/callback?code=${code}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const data = response.data

      if (data?.status ) {
        await setCookieData(response.data.token)
        window.location.replace("/")
      }else{
        setPopupMessage(data?.message)
        setShowPopup(true)
      }
    } catch (error) {
      console.log('Error during authentication:', error)
    }
  }

  const closePopup = async () => {
    setShowPopup(false);
    setPopupMessage(null);
    window.location.replace("/")
  }

  if (!authCode) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <p>Authenticating with Facebook...</p>
      {showPopup && (
        <Popup
          title="Register Status"
          message={popupMessage || ""}
          onClose={closePopup}
        />
      )}
    </div>
  )
}

export default FacebookCallback
