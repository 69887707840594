import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "src/context/UserContext";

const ProtectedRoute: React.FC = () => {
  const { isAuthenticated } = useUser();

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
