import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { PlayCircle, X, FileText } from "lucide-react";
import VideoBackground from "src/components/VideoBackground";
import { Button } from "src/components/ui/button";
import { axiosInstance } from "src/lib/axiosInstance";

interface Video {
  id: number;
  title: string;
  src: string;
  thumbnail: string;
}

interface Document {
  id: number;
  title: string;
  src: string;
}

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export default function LibraryContent() {
  const [activeTab, setActiveTab] = useState<"promotional" | "documents">(
    "promotional"
  );
  const [documents, setDocuments] = useState<Document[]>([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      const response = await axiosInstance.get("/api/documents");
      const data = response.data;
      setDocuments(data);
    };

    fetchDocuments();
  }, []);

  const youtube = [
    "https://www.youtube.com/embed/n8ec7wD2G_M",
    "https://www.youtube.com/embed/rh0SP6ifwXY",
    "https://www.youtube.com/embed/Wo6fXtcw14c",
    "https://www.youtube.com/embed/0fzub7JXfAQ",
    "https://www.youtube.com/embed/cbdAH0xfCuY",
    "https://www.youtube.com/embed/DuepnV2I4pM",
    "https://www.youtube.com/embed/Uy2puDPG2Ro",
    "https://www.youtube.com/embed/eOnxkmiA31g",
    "https://www.youtube.com/embed/kU-ifZ7K8cE",
  ];

  return (
    <div className="relative min-h-screen overflow-hidden">
      <VideoBackground />
      <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
      <div className="relative z-20">
        <motion.div
          className="container mx-auto px-4 py-8 pt-24"
          initial="hidden"
          animate="visible"
          variants={{
            visible: { transition: { staggerChildren: 0.1 } },
          }}
        >
          <motion.h1
            className="text-6xl font-bold text-center mb-16 bg-gradient-to-r from-pink-500 to-purple-500 text-transparent bg-clip-text"
            variants={fadeIn}
          >
            MetaWorm Library
          </motion.h1>

          <motion.div className="flex justify-center mb-12" variants={fadeIn}>
            <div className="inline-flex rounded-lg bg-gray-900/50 p-1">
              <button
                onClick={() => setActiveTab("promotional")}
                className={`px-6 py-2 rounded-lg text-sm font-medium transition-colors ${
                  activeTab === "promotional"
                    ? "bg-purple-600 text-white"
                    : "text-gray-400 hover:text-white"
                }`}
              >
                Promotional Content
              </button>
              <button
                onClick={() => setActiveTab("documents")}
                className={`px-6 py-2 rounded-lg text-sm font-medium transition-colors ${
                  activeTab === "documents"
                    ? "bg-purple-600 text-white"
                    : "text-gray-400 hover:text-white"
                }`}
              >
                Documents Hub
              </button>
            </div>
          </motion.div>

          {activeTab === "promotional" && (
            <motion.div
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
              variants={fadeIn}
            >
              {youtube.map((ele) => {
                return (
                  <div
                    style={{
                      position: "relative",
                      paddingTop: "56.25%",
                    }}
                  >
                    <iframe
                      src={ele}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    ></iframe>
                  </div>
                );
              })}
            </motion.div>
          )}

          {activeTab === "documents" && (
            <motion.div className="max-w-3xl mx-auto" variants={fadeIn}>
              <div className="space-y-4">
                {documents.map((doc) => (
                  <motion.div
                    key={doc.id}
                    className="flex items-center justify-between p-4 bg-gray-900/50 backdrop-blur-sm rounded-lg hover:bg-gray-800/50 transition-colors duration-200"
                    whileHover={{ scale: 1.01 }}
                  >
                    <span className="text-white flex items-center">
                      <FileText className="mr-2 h-5 w-5 text-purple-400" />
                      {doc.title}
                    </span>
                    <Button
                      className="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 transition-colors"
                      onClick={() => window.open(doc.src, "_blank")}
                    >
                      View
                    </Button>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          )}
        </motion.div>
      </div>
    </div>
  );
}
