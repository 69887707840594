import ConnectWalletButton from "src/connect-wallet/ConnectWalletButton";
import { axiosInstance } from "src/lib/axiosInstance";
import { getCookieData } from "src/lib/cookies";
import { signMessage } from "src/lib/signature.utils";
import { useSigner, useAddress } from "@thirdweb-dev/react";
import { useEffect, useRef, useState } from "react";
import Popup from "./Popup";
import { Button } from "./ui/button";

interface RegisterWalletPopupProps {
  onClose: () => void;
}

export const RegisterWalletPopup: React.FC<RegisterWalletPopupProps> = ({
  onClose,
}) => {
  const [popupMessage, setPopupMessage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const user = useRef<any>({});
  const signer = useSigner();
  const address = useAddress();

  useEffect(() => {
    if (signer) {
      user.current.signer = signer;
    }
  }, [signer]);

  useEffect(() => {
    if (address) {
      user.current.address = address;
    }
  }, [address]);

  const handleSignMessage = async () => {
    const currentAddress = user.current.address;
    const currentSigner = user.current.signer;

    if (!currentSigner) {
      setPopupMessage("Connect your wallet first.");
      setShowPopup(true);
      return;
    }

    try {
      const message = await signMessage(currentAddress);
      const sign = await currentSigner.signMessage(message);

      const data = { address: currentAddress, signature: sign };

      const resp = await axiosInstance.post("/wallet", data, {
        headers: { Authorization: `Bearer ${await getCookieData()}` },
      });

      setPopupMessage(resp.data.message);
      if (resp.data.status) {
        window.location.replace("/");
      }
      setShowPopup(true);
    } catch (error) {
      setPopupMessage((error as any).message);
      setShowPopup(true);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    setPopupMessage(null);
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div
        className="bg-gray-900 text-black p-8 rounded-lg w-3/4 max-w-md"
        style={{ display: "block", textAlign: "center" }}
      >
        <h2 className="text-xl font-semibold mb-4" style={{color: "white"}}>Register your wallet</h2>
        {address ? (
          <Button
            onClick={() => handleSignMessage()}
            style={{
              color: "black",
              padding: "20px",
              backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            Register Wallet
          </Button>
        ) : (
          <ConnectWalletButton
            login={false}
            signup={false}
            registerWallet={true}
            claim={false}
            handleWeb3Login={null}
            handleRegister={handleSignMessage}
            handleClaim={null}
          />
        )}
        <div className="mt-4 text-center">
          <Button
            onClick={onClose}
            className="w-full bg-purple-600 hover:bg-purple-700 text-white"
          >
            Close
          </Button>
        </div>
      </div>
      {showPopup && (
        <Popup
          title="Wallet Connect Status"
          message={popupMessage || ""}
          onClose={closePopup}
        />
      )}
    </div>
  );
};
