import React, { useState } from "react";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { X } from "lucide-react";
import { axiosInstance } from "src/lib/axiosInstance";
import { getCookieData } from "src/lib/cookies";
import { Loader } from "./ui/loader";

interface UpdateProfilePopup {
  onClose: () => void;
}

const UpdateProfile: React.FC<UpdateProfilePopup> = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    let valid = true;

    if (!email) {
      valid = false;
      setIsLoading(false);
      alert("Email is required");
    }

    if (valid) {
      let data;
      if (name && email) {
        data = {
          email: email,
          name: name,
        };
      } else {
        data = {
          email: email,
        };
      }
      try {
        const response = await axiosInstance.put("/profile", data, {
          headers: { Authorization: `Bearer ${await getCookieData()}` },
        });

        if (response.data.status) {
          onClose();
          window.location.reload();
        } else {
          alert(response?.data?.message);
          setIsLoading(false);
        }
      } catch (error) {
        alert(
          (error as any).response?.data?.message || "Something went wrong.."
        );
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
      <div className="bg-gray-900 p-8 rounded-lg shadow-lg max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          <X size={24} />
        </button>
        <h2 className="text-2xl font-bold mb-8 text-white text-center">
          Provide email to proceed
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <Input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full bg-gray-800 text-white border-gray-700"
          />

          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full bg-gray-800 text-white border-gray-700"
          />

          <Button
            type="submit"
            className="w-full bg-purple-600 hover:bg-purple-700 text-white py-6"
          >
            {isLoading ? <Loader /> : "Update"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default UpdateProfile;
