import ClientLayout from 'src/components/ClientLayout'
import { useGlobalData } from 'src/context/GlobalDataContext'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import EmailVerificationPopup from 'src/components/EmailVerificationPopup'
import { axiosInstance } from 'src/lib/axiosInstance'
import { getCookieData } from 'src/lib/cookies'

export default function HomePage() {
  const [showPopup, setShowPopup] = useState(false)
  const [popupMessage, setPopupMessage] = useState<string | null>(null)
  const navigate = useNavigate()

  const globalData: any = useGlobalData()

  const onSubmit = async() => {
    try {

      const response = await axiosInstance.get("/verify-email", {headers: {Authorization: `Bearer ${await getCookieData()}`}})

      setPopupMessage(response.data?.message)
      setShowPopup(true)

    } catch (error) {
      setPopupMessage((error as any).response.data.message)
    }
  }

  const onClose = () => setShowPopup(false)

  useEffect(()=>{

    if(globalData && globalData?.status === false){
      
      if(globalData?.missing?.pendingEmailVerification && !globalData?.missing?.email){
        setPopupMessage("Please verify your email to proceed...")
        return
      }
    }
  }, [globalData])

  return (
    <main className="min-h-screen">
      <ClientLayout profileData= {globalData} verifyEmail = { () =>setShowPopup(true)}/>

      {showPopup && (
        <EmailVerificationPopup 
          title="Verify Email" 
          message={popupMessage || ""} 
          onSubmit={onSubmit} 
          onClose={onClose}
        />
      )}
    </main>
  )
}
