
import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Button } from "src/components/ui/button"
import { FaDiscord, FaTwitter, FaTelegram, FaLinkedin } from 'react-icons/fa'
import { Brain, Coins, ShoppingBag, Zap, Users, Globe, ChevronDown } from 'lucide-react'
import VideoBackground from './VideoBackground'
import LoginPopup from './LoginPopup'
import RegisterPopup from 'src/components/RegisterPopup'
import ForgotPasswordPopup from 'src/components/ForgotPasswordPopup'
import GlobalAccessibilityPopup from 'src/components/GlobalAccessibilityPopup'
import InstantPayoutsPopup from 'src/components/InstantPayoutsPopup'
import CommunityDrivenPopup from 'src/components/CommunityDrivenPopup'
import { useNavigate } from 'react-router-dom'
import UpdateProfile from './UpdateProfile'

const features = [
  { id: 1, name: 'Trivia Games', icon: Brain, description: 'Learn about MetaWorm and earn rewards as you play.', link: '/trivia' },
  { id: 2, name: 'Token Rewards', icon: Coins, description: 'Get paid while beta testing our innovative Proof of Action (POA) mining.', link: '/dashboard' },
  { id: 3, name: 'MetaWorm NFT', icon: ShoppingBag, description: 'Your all-inclusive, lifetime pass to every partnered metaverse venue.', link: '/dashboard' },
  { id: 4, name: 'Instant Payouts', icon: Zap, description: 'Access credits now; claim tokens after AML, KYC, and mainnet launch.' },
  { id: 5, name: 'Community Driven', icon: Users, description: 'Join our metaverse community on Discord and Telegram.' },
  { id: 6, name: 'Virtual Venues', icon: Globe, description: 'Build your metaverse venue with our two-tier plan.' },
]

const socialLinks = [
  { id: 1, icon: FaDiscord, label: "Discord", url: "https://discord.com/invite/6t87ukrZmy" },
  { id: 2, icon: FaTwitter, label: "Twitter", url: "https://x.com/themetaworm?s=21&t=HjUu1b4Yigrkahtse0IHMA" },
  { id: 3, icon: FaTelegram, label: "Telegram", url: "https://t.me/+XSa0ZZ9K438wOTg5" },
  { id: 4, icon: FaLinkedin, label: "LinkedIn", url: "https://www.linkedin.com/company/metaworm/" }
]
interface ClientLayoutProps {
  profileData: any; 
  verifyEmail: () => void;
}
export default function ClientLayout({ profileData, verifyEmail }: ClientLayoutProps) {
  const [showLoginPopup, setShowLoginPopup] = useState(false)
  const [showRegisterPopup, setShowRegisterPopup] = useState(false)
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false)
  const [showGlobalAccessibilityPopup, setShowGlobalAccessibilityPopup] = useState(false)
  const [showInstantPayoutsPopup, setShowInstantPayoutsPopup] = useState(false)
  const [showCommunityDrivenPopup, setShowCommunityDrivenPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isTLoading, setIsTLoading] = useState(false)
  const [showUpdateProfile, setShowUpdateProfile] = useState(false)
  const aboutSectionRef = useRef<HTMLElement>(null)
  const navigate = useNavigate()
  
  const openLoginPopup = () => {
    setIsLoading(true)
    if (profileData) {
      if (!profileData.status && profileData.missing?.email) {
        openProfileUpdatePopup();
        return;
      }
    }
  
    setShowLoginPopup(true);
  };

  const playTrivia =()=>{
    setIsTLoading(true)
    const { email, walletAddress, NFT_claim_status } = profileData?.userProfile || {};
    if (email || walletAddress) {
      navigate(NFT_claim_status ? "/trivia" : "/claim-nft");
      return;
    }
    

    if (!profileData.status && profileData.missing?.email) {
      openProfileUpdatePopup();
      return;
    }

    if (!profileData.status && profileData.missing?.pendingEmailVerification) {
      setIsTLoading(false)
      verifyEmail()
      return;
    }
  }
  
  const closeLoginPopup = () =>{
    setShowLoginPopup(false)
    setIsLoading(false)
  }

  const openRegisterPopup = () => {
    setShowLoginPopup(false)
    setShowRegisterPopup(true)
  }
  const closeRegisterPopup = () =>{
    setShowRegisterPopup(false)
    setIsLoading(false)
  }

  const openForgotPasswordPopup = () => {
    setShowLoginPopup(false)
    setShowForgotPasswordPopup(true)
  }
  const closeForgotPasswordPopup = () => {
    setShowForgotPasswordPopup(false)
    setIsLoading(false)
  }

  const openClaimNFTModal = () =>{
    setIsLoading(true)
    navigate("/claim-nft")
  }

  const openGlobalAccessibilityPopup = () => setShowGlobalAccessibilityPopup(true)
  const closeGlobalAccessibilityPopup = () => setShowGlobalAccessibilityPopup(false)

  const openInstantPayoutsPopup = () => setShowInstantPayoutsPopup(true)
  const closeInstantPayoutsPopup = () => setShowInstantPayoutsPopup(false)

  const openCommunityDrivenPopup = () => setShowCommunityDrivenPopup(true)
  const closeCommunityDrivenPopup = () => setShowCommunityDrivenPopup(false)

  const closeProfileUpdatePopup = () => {
    setShowUpdateProfile(false)
    setIsTLoading(false)
  }
  const openProfileUpdatePopup = () => setShowUpdateProfile(true)


  const scrollToNextSection = () => {
    aboutSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

   return ( 
    <div className="relative min-h-screen text-white overflow-hidden">
      <div className="fixed inset-0 z-0">
        <VideoBackground />
      </div>

      <div className="relative z-10">
        <main>
          {/* Hero Section */}
          <section className="relative min-h-screen flex flex-col items-center justify-center">
            <div className="absolute inset-0 bg-black bg-opacity-30"></div>
            <div className="relative z-10 text-center px-4 sm:px-6 space-y-6">
              <motion.h1 
                className="text-5xl sm:text-6xl font-bold mb-6 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-transparent bg-clip-text"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
              >
                Welcome to MetaWorm
              </motion.h1>
              <motion.p 
                className="text-xl mb-8 text-white"
                initial={{ opacity: 0, y: -30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
              >
                Dive into the future of trivia and earn tokens in our immersive metaverse
              </motion.p>
              <motion.div 
                className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4"
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.4 }}
              >
                <Button 
                  variant="default" 
                  size="lg"
                  onClick={openClaimNFTModal}
                  disabled = {isLoading}
                  className="w-full sm:w-auto bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-neon-purple"
                >
                   {isLoading ? (
                    <div className="flex items-center justify-center space-x-2">
                      <div className="loader border-t-2 border-white w-4 h-4 rounded-full animate-spin"></div>
                    </div>
                  ) : (profileData && profileData?.userProfile?.NFT_claim_status ? "NFT Claimed" :"Claim Your Free NFT")}
                   
                </Button>
                {profileData && profileData?.userProfile?.email || profileData?.userProfile?.walletAddress || profileData?.status === false  ?
                 <Button 
                  variant="outline" 
                  size="lg"
                  onClick={playTrivia}
                  disabled= {isTLoading}
                  className="w-full sm:w-auto bg-transparent border-white text-white hover:bg-white hover:text-purple-600 font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-neon-blue"
                >
                   {isTLoading ? (
                      <div className="flex items-center justify-center space-x-2">
                        <div className="loader border-t-2 border-white w-4 h-4 rounded-full animate-spin"></div>
                      </div>
                    ) : "Play to Earn Tokens"}
                  
                </Button> : 
                <Button 
                variant="outline" 
                size="lg"
                onClick={openLoginPopup}
                className="w-full sm:w-auto bg-transparent border-white text-white hover:bg-white hover:text-purple-600 font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-neon-blue"
              >
                Get Started

              </Button>
                }
              </motion.div>
            </div>
            <motion.div 
              className="absolute bottom-0 left-0 right-0 flex justify-center items-center p-4 sm:p-6 md:p-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut", delay: 0.6, repeat: Infinity, repeatType: "reverse" }}
            >
              <Button
                variant="ghost"
                size="icon"
                onClick={scrollToNextSection}
                className="text-white hover:text-purple-400 transition-colors duration-300"
                aria-label="Scroll to next section"
              >
                <ChevronDown className="w-8 h-8 sm:w-10 sm:h-10" />
              </Button>
            </motion.div>
          </section>

          {/* About Section */}
          <section id="about" ref={aboutSectionRef} className="relative py-20">
            <div className="absolute inset-0 bg-gray-900 bg-opacity-75"></div>
            <div className="container relative z-10 mx-auto px-4 sm:px-6">
              <div className="max-w-4xl mx-auto text-center">
                <h2 className="text-4xl font-bold mb-8 bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">About MetaWorm</h2>
                <div className="space-y-6 text-lg">
                  <p className="backdrop-blur-sm bg-gray-900 bg-opacity-50 p-4 rounded-lg shadow-lg">
                    MetaWorm is an innovative platform designed to unify the fragmented metaverse ecosystem. We are building a suite of interconnected portals that seamlessly connect different metaverse venues, enabling asset portability and creating an unparalleled user experience.
                  </p>
                  <p className="backdrop-blur-sm bg-gray-900 bg-opacity-50 p-4 rounded-lg shadow-lg">
                    Our platform focuses on interoperability, allowing users to carry their avatars, digital assets, and wearables across multiple metaverses without friction. With features like Interconnected Portals, Quantum Beacon Technology, and Digital Twin NFTs, MetaWorm is setting the standard for the next generation of metaverse platforms.
                  </p>
                  <div className="mt-8">
                    <Link to="/about-us" className="inline-block">
                      <Button variant="outline" size="lg" className="bg-transparent border-white text-white hover:bg-white hover:text-purple-600 font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-neon-blue">
                        Read More About MetaWorm
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Features Section */}
          <section id="features" className="relative py-20">
            <div className="absolute inset-0 bg-gray-900 bg-opacity-75"></div>
            <div className="container relative z-10 mx-auto px-4 sm:px-6">
              <h2 className="text-4xl font-bold mb-12 text-center">Features</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {features.map((feature) => (
                  <div key={feature.id} className="bg-gray-800 bg-opacity-50 backdrop-blur-sm p-6 rounded-lg shadow-lg transform transition duration-300 hover:scale-105 flex flex-col items-center text-center">
                    {feature.link ? (
                      <Link to={feature.link} className="w-full h-full flex flex-col items-center">
                        <div className="flex items-center justify-center mb-4">
                          <feature.icon className="w-12 h-12 text-purple-400" />
                        </div>
                        <h3 className="text-2xl font-semibold mb-4 text-purple-400">{feature.name}</h3>
                        <p>{feature.description}</p>
                      </Link>
                    ) : feature.name === 'Virtual Venues' ? (
                      <button 
                        onClick={openGlobalAccessibilityPopup}
                        className="w-full h-full flex flex-col items-center text-left"
                      >
                        <div className="flex items-center justify-center mb-4">
                          <feature.icon className="w-12 h-12 text-purple-400" />
                        </div>
                        <h3 className="text-2xl font-semibold mb-4 text-purple-400">{feature.name}</h3>
                        <p>{feature.description}</p>
                      </button>
                    ) : feature.name === 'Instant Payouts' ? (
                      <button 
                        onClick={openInstantPayoutsPopup}
                        className="w-full h-full flex flex-col items-center text-left"
                      >
                        <div className="flex items-center justify-center mb-4">
                          <feature.icon className="w-12 h-12 text-purple-400" />
                        </div>
                        <h3 className="text-2xl font-semibold mb-4 text-purple-400">{feature.name}</h3>
                        <p>{feature.description}</p>
                      </button>
                    ) : feature.name === 'Community Driven' ? (
                      <button 
                        onClick={openCommunityDrivenPopup}
                        className="w-full h-full flex flex-col items-center text-left"
                      >
                        <div className="flex items-center justify-center mb-4">
                          <feature.icon className="w-12 h-12 text-purple-400" />
                        </div>
                        <h3 className="text-2xl font-semibold mb-4 text-purple-400">{feature.name}</h3>
                        <p>{feature.description}</p>
                      </button>
                    ) : (
                      <>
                        <div className="flex items-center justify-center mb-4">
                          <feature.icon className="w-12 h-12 text-purple-400" />
                        </div>
                        <h3 className="text-2xl font-semibold mb-4 text-purple-400">{feature.name}</h3>
                        <p>{feature.description}</p>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Partnership Section */}
          <section id="partnership" className="relative py-20">
            <div className="absolute inset-0 bg-gray-900 bg-opacity-75"></div>
            <div className="container relative z-10 mx-auto px-4 sm:px-6">
              <h2 className="text-4xl font-bold mb-12 text-center">Strategic Partnership</h2>
              <div className="bg-gray-800 bg-opacity-50 backdrop-blur-sm p-8 rounded-lg shadow-lg text-center max-w-2xl mx-auto">
                <h3 className="text-2xl font-semibold mb-4">Powered by ChainSafe</h3>
                <p className="mb-6">Leveraging ChainSafe&apos;s robust blockchain infrastructure for a secure and scalable experience.</p>
                <img 
                  src="https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/5646cd55-8803-4fec-bc22-95c20ad0dd00/public" 
                  alt="ChainSafe Logo" 
                  className="mx-auto object-contain" 
                  width={64} 
                  height={64}
                />
              </div>
            </div>
          </section>

          {/* Countdown Section */}
          {/* <section id="countdown" className="relative py-20 text-center">
            <div className="absolute inset-0 bg-gray-900 bg-opacity-75"></div>
            <div className="container relative z-10 mx-auto px-4 sm:px-6">
              <h2 className="text-4xl font-bold mb-12">NFT Launch Countdown</h2>
              <CountdownTimer launchDate="December 8, 2024 10:00:00 PST" />
            </div>
          </section> */}
        </main>

        {/* Footer */}
        <footer className="relative py-12">
          <div className="absolute inset-0 bg-gray-900 bg-opacity-75"></div>
          <div className="container relative z-10 mx-auto px-4 sm:px-6 flex flex-col items-center">
            <div className="flex items-center mb-6">
              <img 
                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/WhatsApp%20Image%202024-11-22%20at%2011.25.19%20AM-JPCxIWZUF1TsXPWJ4JzvPYHhjyFojV.jpeg" 
                width={64} 
                height={64} 
                alt="MetaWorm Logo" 
                className="h-10 w-10 mr-2 object-contain bg-white rounded-full p-1" 
              />
              <span className="text-2xl font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">MetaWorm</span>
            </div>
            <div className="flex justify-center space-x-6 mb-6">
              {socialLinks.map((social) => (
                <a 
                  key={social.id}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-2xl hover:text-purple-400 transition"
                  aria-label={social.label}
                >
                  <social.icon />
                </a>
              ))}
            </div>
            
          </div>
          <div className="text-center mt-6 relative z-10">
            <p>&copy; 2024 MetaWorm. All rights reserved.</p>
            <div className="mt-4">
              <Button 
                onClick={()=> navigate("/terms")}
                className="text-sm text-gray-400 hover:text-purple-300 transition-colors mx-1"
              >
                Terms and Conditions
              </Button>
              <span className="text-gray-400">|</span>
              <Button 
                onClick={()=> navigate("/privacy-policy")}
                className="text-sm text-gray-400 hover:text-purple-300 transition-colors mx-1"
              >
                Privacy Policy
              </Button>
            </div>
            <p className="mt-2 text-sm text-gray-400">
              Designed by{' '}
              <a
                href="https://cresol.ai"
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-400 hover:text-purple-300 transition-colors"
              >
                CRESOL.AI
              </a>
            </p>
          </div>
        </footer>

      </div>

      {showUpdateProfile && (
        <UpdateProfile onClose={closeProfileUpdatePopup} />
      )}
      
      {showLoginPopup && (
        <LoginPopup 
          onClose={closeLoginPopup} 
        />
      )}
      {showRegisterPopup && <RegisterPopup onClose={closeRegisterPopup} />}
      {showForgotPasswordPopup && <ForgotPasswordPopup onClose={closeForgotPasswordPopup} />}
      <GlobalAccessibilityPopup isOpen={showGlobalAccessibilityPopup} onClose={closeGlobalAccessibilityPopup} />
      <InstantPayoutsPopup isOpen={showInstantPayoutsPopup} onClose={closeInstantPayoutsPopup} />
      <CommunityDrivenPopup isOpen={showCommunityDrivenPopup} onClose={closeCommunityDrivenPopup} />
    </div>
  )
}

