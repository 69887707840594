import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { cn } from "src/lib/utils";
import { Menu, X, User } from "lucide-react";
import { Button } from "src/components/ui/button";
import { removeCookieData } from "src/lib/cookies";
import { useGlobalData } from "src/context/GlobalDataContext";
import LoginPopup from "./LoginPopup";

interface NavItem {
  href: string;
  text: string;
}

const navItems: NavItem[] = [
  { href: "/", text: "Home" },
  { href: "/#about", text: "About" },
  { href: "/about-us", text: "Features" },
  { href: "/#partnership", text: "Partnership" },
  { href: "/wallet-guide", text: "Wallet Guide" },
  { href: "/library", text: "Library" },
];

export default function Header() {
  const [activeSection, setActiveSection] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const globalData: any = useGlobalData();

  const openLoginPopup = () => {
    setShowLoginPopup(true);
  };

  const closeLoginPopup = () => {
    setShowLoginPopup(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);

      const sections = ["about", "features", "partnership"];
      const current = sections.find((section) => {
        const element =
          typeof window !== "undefined" ? document.getElementById(section) : "";
        if (!element) return false;
        const rect = element.getBoundingClientRect();
        return rect.top <= 100 && rect.bottom >= 100;
      });
      if (current) setActiveSection(current);
    };

    setIsMenuOpen(false);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleProfile = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const handleNavClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    href: string
  ) => {
    e.preventDefault();
    if (href === "/") {
      navigate("/");
    } else if (href.startsWith("/#")) {
      if (pathname === "/") {
        const targetId = href.replace("/#", "");
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        navigate(href);
      }
    } else {
      navigate(href);
    }
    setIsMenuOpen(() => false);
    setIsProfileOpen(() => false);
  };

  const handleLogout = async () => {
    await removeCookieData();
    window.location.replace("/");
  };

  const isActive = (href: string) => {
    if (href === "/") return pathname === href;
    if (href.startsWith("/#")) return activeSection === href.replace("/#", "");
    return pathname === href;
  };

  return (
    <>
      <header
        className={cn(
          "fixed top-0 left-0 right-0 z-50 transition-all duration-300",
          scrolled ? "py-2" : "py-4",
          "bg-opacity-20 backdrop-blur-md"
        )}
      >
        <div className="container mx-auto px-4 sm:px-6">
          <div className="flex items-center justify-between">
            <motion.div
              className="flex items-center space-x-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Link
                to="/"
                onClick={() => {
                  setIsMenuOpen(false);
                  setIsProfileOpen(false);
                }}
                className="flex items-center space-x-2"
              >
                <div className="relative w-12 h-12 bg-white rounded-full p-1 overflow-hidden">
                  <img
                    src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/WhatsApp%20Image%202024-11-22%20at%2011.25.19%20AM-JPCxIWZUF1TsXPWJ4JzvPYHhjyFojV.jpeg"
                    alt="MetaWorm Logo"
                    className="w-full h-full object-contain rounded-full transform hover:scale-110 transition-transform duration-300"
                  />
                  <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-full" />
                </div>
                <span className="text-2xl font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text font-display">
                  MetaWorm
                </span>
              </Link>
            </motion.div>

            <nav className="hidden md:flex items-center space-x-8">
              <motion.ul
                className="flex space-x-8"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                {navItems.map((item, index) => (
                  <motion.li
                    key={item.href}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 * (index + 1) }}
                  >
                    <Link
                      to={item.href}
                      onClick={(e) => {
                        handleNavClick(e, item.href);
                        setIsMenuOpen(() => false);
                      }}
                      className={cn(
                        "relative py-2 px-1 text-sm font-medium transition-colors font-display",
                        "hover:text-purple-400",
                        isActive(item.href) ? "text-purple-400" : "text-white"
                      )}
                    >
                      {item.text}
                      {isActive(item.href) && (
                        <motion.div
                          layoutId="activeSection"
                          className="absolute -bottom-1 left-0 right-0 h-0.5 bg-gradient-to-r from-purple-400 to-pink-500"
                          initial={false}
                          transition={{
                            type: "spring",
                            stiffness: 380,
                            damping: 30,
                          }}
                        />
                      )}
                    </Link>
                  </motion.li>
                ))}
              </motion.ul>

              {globalData ? (
                <div className="relative">
                  <Button
                    onClick={toggleProfile}
                    variant="ghost"
                    className="p-2 rounded-full hover:bg-gray-800/50 transition-colors"
                    aria-label="Profile menu"
                  >
                    <User className="h-6 w-6 text-white" />
                  </Button>
                  <AnimatePresence>
                    {isProfileOpen && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                        className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg py-1 z-50"
                      >
                        <Link
                          to="/dashboard"
                          onClick={() => setIsProfileOpen(false)}
                          className="block px-4 py-2 text-sm text-white hover:bg-gray-700"
                        >
                          Dashboard
                        </Link>
                        <button
                          onClick={handleLogout}
                          className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700"
                        >
                          Log out
                        </button>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ) : (
                <ul>
                  <li>
                    <button
                      onClick={() => openLoginPopup()}
                      className="text-white hover:text-purple-400 transition-colors"
                    >
                      Login
                    </button>
                  </li>
                </ul>
              )}
            </nav>

            <button
              className="md:hidden p-2 rounded-lg hover:bg-gray-800/50 transition-colors"
              onClick={toggleMenu}
              aria-expanded={isMenuOpen}
              aria-label="Toggle navigation menu"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6 text-white" />
              ) : (
                <Menu className="h-6 w-6 text-white" />
              )}
            </button>
          </div>
        </div>

        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="md:hidden absolute top-full left-0 right-0 bg-gray-900/80 backdrop-blur-md py-4 px-6 shadow-lg"
            >
              <ul className="space-y-4">
                {navItems.map((item) => (
                  <li key={item.href}>
                    <Link
                      to={item.href}
                      onClick={(e) => handleNavClick(e, item.href)}
                      className={cn(
                        "text-white hover:text-purple-400 transition-colors",
                        isActive(item.href) && "text-purple-400"
                      )}
                    >
                      {item.text}
                    </Link>
                  </li>
                ))}

                {globalData ? (
                  <>
                    <li>
                      <Link
                        to="/dashboard"
                        onClick={() => setIsMenuOpen(false)}
                        className="text-white hover:text-purple-400 transition-colors"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <button
                        onClick={handleLogout}
                        className="text-white hover:text-purple-400 transition-colors"
                      >
                        Logout
                      </button>
                    </li>
                  </>
                ) : (
                  <li>
                    <button
                      onClick={() => {
                        openLoginPopup();
                        setIsMenuOpen(false);
                      }}
                      className="text-white hover:text-purple-400 transition-colors"
                    >
                      Login
                    </button>
                  </li>
                )}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </header>
      {showLoginPopup && (
        <div style={{ position: "absolute", top: 50 }}>
          <LoginPopup onClose={closeLoginPopup} />
        </div>
      )}
    </>
  );
}
